import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Enroll.module.scss";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Col, Row, Button, Dropdown } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomDatePicker from '../../../components/Student/Datepicker/ReschduleDatePicker';
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import { format } from "date-fns";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from "react-loading";
import moment from "moment";
import "moment/locale/es";
import mtimezone from "moment-timezone";

export default function Enroll() {
  let localData = JSON.parse(localStorage.getItem("userData")!);
  const navigate = useHistory();
  const location: any = useLocation();

  const [selectedTime, setSelectedTime] = useState<any[]>([]);
  const [data, setData] = useState<any>([{
    select_date: "",
    type: "1",
    start_time: [],
    end_time: [],
    // enroll_sorting_date: null,
    date: "",
    teacher_availabilty_of_slots_id: location?.state?.item?.plans?.[0]?.teacher_availabilty_of_slots_id,
    teacher_availabilty_of_slots_details_id: location?.state?.item?.teacher_availabilty_of_slots_details_id,
    zoom_url: location?.state?.item?.zoom_link
  }]);

  const [remainigData] = useState<any>([
    {
      select_date: "yy-mm-dd",
      type: "1",
      start_time: ['hh-mm'],
      // enroll_sorting_date: null,
      end_time: ['hh-mm'],
      date: "yy-mm-dd",
      teacher_availabilty_of_slots_id: location?.state?.item?.plans?.[0]?.teacher_availabilty_of_slots_id,
      teacher_availabilty_of_slots_details_id: location?.state?.item?.teacher_availabilty_of_slots_details_id,
      zoom_url: location?.state?.item?.zoom_link
    }
  ]);

  // SELECTED TIME
  let selectedDayCount: any = []
  data?.map((item: any) => {
    item?.start_time?.map((e: any) => {
      selectedDayCount.push(e)
    })
  })

  const [date, setDate] = useState("");

  const selectedDay: any = (val: any) => {
    let date = format(val, "yyyy-MM-dd")
    let selectedData: any[] = []
    data?.map((e: any) => {
      if (e.date === date)
        e?.start_time?.map((a: any) => {
          selectedData.push({
            start_time: a?.toString(),
            date: e?.date
          })
        })
    })

    setSelectedTime(selectedData);
    studentSlotBook(date, val.getDay())
  };

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  let [count, setCount] = useState<any>(1);
  const [selectedOption, setSelectedOption] = useState("1 to 4 Sessions");
  const [sessions, setSessions] = useState<any[]>([]);
  const [curr, setCurr] = useState("");
  const [active, setActive] = useState<any>(0);
  const [allData, setAllData] = useState<any[]>([]);
  const [get_TeacherAvailabiltyOfSlots, setGet_TeacherAvailabiltyOfSlots] = useState<any>([]);

  const incrementCount = () => {
    setCount((prevCount: any) => prevCount + 1);
  };

  const decrementCount = () => {
    setCount((prevCount: any) => Math.max(prevCount - 1, 1));
  };

  // function decrementCount() {
  //   if (count > +session?.split(" ")[0]) {
  //     count = count - 1;
  //     setCount(count);
  //     // setPrice(location?.state?.class_rate * count);
  //   }
  //   else {
  //     setCount(+session?.split(" ")[0]);
  //   }
  // }

  const updateSelectedOptionAndPrice = () => {
    const selectedSession = sessions.find(session => {
      const range = session.name.split(" ")[0].split("-");
      const minCount = parseInt(range[0]);
      const maxCount = parseInt(range[2] || range[0]); // If there's no upper limit specified, set it to the lower limit
      return count >= minCount && count <= maxCount;
    });
    if (selectedSession) {
      setSelectedOption(selectedSession.name);
      // setCount(+selectedSession.name?.split(" ")[0]);
    } else {
      setSelectedOption("");
    }
  };

  useEffect(() => {
    updateSelectedOptionAndPrice();
  }, [count, sessions]);

  const handleSelectChange = (e: any) => {
    const selectedSession = sessions.find(session => session.name == e);
    if (selectedSession) {
      setSelectedOption(selectedSession.name);
      setCount(+selectedSession.name?.split(" ")[0]);
    } else {
      setSelectedOption("");
    }
  };

  // Update the selected option based on the count
  const updateSelectedOption = () => {
    if (count >= 1 && count <= 4) {
      setSelectedOption("1 to 4 Sessions");
    } else if (count >= 5 && count <= 9) {
      setSelectedOption("5 to 9 Sessions");
    } else if (count >= 10 && count <= 15) {
      setSelectedOption("10 to 15 Sessions");
    } else if (count >= 16 && count <= 24) {
      setSelectedOption("16 to 24 Sessions");
    } else if (count >= 25 && count <= 50) {
      setSelectedOption("25 to 50 Sessions");
    } else {
      setSelectedOption("51 or more Sessions");
    }
  };

  useEffect(() => {
    updateSelectedOption();
  }, [count])

  useEffect(() => {
    let selectedData: any[] = []
    data?.map((e: any) => {
      setloaderStatus(<ReactLoading type="cylon" color="blue" />)
      if (e.date === date)
        e?.start_time?.map((a: any) => {
          selectedData.push({
            start_time: a?.toString(),
            date: e?.date
          })
        })
    })

    setSelectedTime(selectedData);
    setGet_TeacherAvailabiltyOfSlots((prev: any) => [...prev])
  }, [active])

  useEffect(() => {
    setGet_TeacherAvailabiltyOfSlots(allData);
  }, [allData])

  // --------------------------------STUDENT BOOK SLOT-----------------------------------//
  function studentSlotBook(date: any, dayNumber: number) {
    let dataArr = data.filter((e: any) => e.select_date != "");
    let formdata = new FormData();
    formdata.append('date', date);
    formdata.append('teacher_id', location?.state?.item?.teacher_assign_id || location?.state?.item?.teacher_id);
    formdata.append('data', JSON.stringify(dataArr));

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/book_Slot_student`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        let localdate = '';
        setAllData([])
        const bookedData: any[] = response.data?.data
        let dataArr: any[] = []
        bookedData?.map((item: any) => {
          const dateStrStart = `${item?.select_date}T${item?.utc_select_time}Z`;  // Adds 'T' between date and time, 'Z' to indicate UTC

          let localTime = new Date(dateStrStart).toLocaleString("en-US", { timeZone: localData?.timezone });
          let dateAndTimeArr = localTime?.split(",")
          let dateCheck = dateAndTimeArr[0]?.toString().split("/");
          let dateEndTime = dateAndTimeArr[1]?.toString().split(":");
          dataArr.push({
            day: item?.day,
            id: item?.id,
            select_date: `${dateCheck[2]}-${+dateCheck[0] < 10 ? `0${dateCheck[0]}` : dateCheck[0]}-${+dateCheck[1] < 10 ? `0${dateCheck[1]}` : dateCheck[1]}`,
            studentapplyfordemo_id: item?.studentapplyfordemo_id,
            teacher_assign_id: item?.teacher_assign_id,
            local_select_end_time: `${+dateEndTime[0] + 1}:${dateEndTime[1]}:${dateEndTime[2]}`,
            local_select_time: dateAndTimeArr[1],
            utcStart: item?.utc_select_time,
            utcEnd: item?.utc_select_end_time,
            start_time: item?.start_time
          })
        })
        localdate = date;
        let checkDate = localdate?.split("-");
        let availability: any[] = []
        response?.data?.get_TeacherAvailabiltyOfSlots?.map((item: any, index: number) => {
          let num = dayNumber - 1;
          num = num < 0 ? 6 : num;
          let plus = dayNumber + 1;
          plus = plus > 6 ? 0 : plus;
          if (dayNumber == index || plus == index || num == index) {
            let check = plus == index ? 1 : (num == index ? -1 : 0);
            // let dateAgain = +checkDate[2] + check
            // let dateOnce = `${checkDate[0]}-${+checkDate[1]}-${dateAgain < 10 ? `0${dateAgain == 0 ? 2 : dateAgain}` : dateAgain}`;
            let newDate = new Date(`${checkDate[0]}-${checkDate[1]}-${checkDate[2]}`);
            newDate.setDate(newDate.getDate() + check);
            let dateAgain = newDate.getDate();
            let monthAgain = newDate.getMonth() + 1; // Months are 0-based in JS
            let yearAgain = newDate.getFullYear();

            let dateOnce = `${yearAgain}-${monthAgain < 10 ? `0${monthAgain}` : monthAgain}-${dateAgain < 10 ? `0${dateAgain}` : dateAgain}`;
            // item?.teacher_availabilty_of_slots_details?.map((e: any) => {
            //   e?.utctime?.map((y: any) => {
            //     if (y?.start_time != null) {
            //       let localTime = new Date(`${dateOnce} ${y?.start_time} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
            //       let dateAndTimeArr = localTime.split(",");
            //       let dateCheck = dateAndTimeArr[0]?.toString()?.split("/");
            //       let dateEndTime = dateAndTimeArr[1]?.toString()?.split(":");
            //       availability.push({
            //         day: index,
            //         teacher_availabilty_of_slots_id: e?.teacher_availabilty_of_slots_id,
            //         select_date: `${dateCheck[2]}-${+dateCheck[0] < 10 ? `0${dateCheck[0]}` : dateCheck[0]}-${+dateCheck[1] < 10 ? `0${dateCheck[1]}` : dateCheck[1]}`,
            //         teacher_id: e?.teacher_id,
            //         local_select_end_time: `${+dateEndTime[0] + 1}:${dateEndTime[1]}:${dateEndTime[2]}`,
            //         local_select_time: dateAndTimeArr[1],
            //         zoom_link: e?.zoom_link,
            //         delete_time_status: e?.delete_time_status,
            //         request_status: e?.request_status,
            //         reschedule_status: e?.reschedule_status,
            //         uid: e?.uid,
            //         checked: "",
            //         utcStart: y?.start_time,
            //         utcEnd: y?.end_time,
            //         start_time: dateAndTimeArr[1]
            //       })
            //     }
            //   })
            // })

            item?.teacher_availabilty_of_slots_details?.map((e: any) => {
              e?.utctime?.map((y: any) => {
                if (y?.start_time != null) {
                  // Construct ISO 8601 compliant date string
                  let isoDateString = `${dateOnce}T${y?.start_time}Z`; // 'Z' indicates UTC

                  // Convert to local time using the timezone
                  let localTime = new Date(isoDateString).toLocaleString("en-US", { timeZone: localData?.timezone });

                  let dateAndTimeArr = localTime.split(",");
                  let dateCheck = dateAndTimeArr[0]?.toString()?.split("/");
                  let dateEndTime = dateAndTimeArr[1]?.toString()?.split(":");

                  availability.push({
                    day: index,
                    teacher_availabilty_of_slots_id: e?.teacher_availabilty_of_slots_id,
                    select_date: `${dateCheck[2]}-${+dateCheck[0] < 10 ? `0${dateCheck[0]}` : dateCheck[0]}-${+dateCheck[1] < 10 ? `0${dateCheck[1]}` : dateCheck[1]}`,
                    teacher_id: e?.teacher_id,
                    local_select_end_time: `${+dateEndTime[0] + 1}:${dateEndTime[1]}:${dateEndTime[2]}`,
                    local_select_time: dateAndTimeArr[1],
                    zoom_link: e?.zoom_link,
                    delete_time_status: e?.delete_time_status,
                    request_status: e?.request_status,
                    reschedule_status: e?.reschedule_status,
                    uid: e?.uid,
                    checked: "",
                    utcStart: y?.start_time,
                    utcEnd: y?.end_time,
                    start_time: dateAndTimeArr[1]
                  })
                }
              })
            });
          }
        })
        let filterDataArr = dataArr?.filter((e: any) => {
          return localdate === e?.select_date
        })
        let filterAvailabilityArr = availability?.filter((e: any) => {
          return localdate === e?.select_date
        })
        setDate(localdate)
        let da: any[] = []
        filterAvailabilityArr?.map((e: any) => {
          let count = 0
          filterDataArr?.map((a: any) => {
            if (a?.utcStart === e?.utcStart) {
              count = count + 1
            }
          })
          if (count === 0) {
            da.push(e)
          }
        })
        setAllData(da)
        if (response.data.get_TeacherAvailabiltyOfSlots.teacher_availabilty_of_slots_details) {
          setloaderStatus(<div>Time Not Available ...</div>);
        }
        if (response.data.data) {
          setloaderStatus(<div>Time Not Available ...</div>);
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  useEffect(() => {
    const currency = localData?.currency?.split("-")?.[0];
    const plans = location?.state?.item?.plans || [];

    let selectedPlan: any = null;
    let checkArr = [];

    for (let i = 0; i < plans.length; i++) {
      const frequencies = plans[i]?.Planfrequencys || [];
      for (let j = 0; j < frequencies.length; j++) {
        const frequency = frequencies[j];

        // Check for a currency match
        if (currency === frequency.currency) {
          selectedPlan = frequency;
          break;
        }

        // Store USD plan as fallback
        if (frequency.currency === "USD") {
          selectedPlan = selectedPlan || frequency;
        }
      }

      if (selectedPlan) break;
    }

    if (selectedPlan) {
      // Populate the session array
      checkArr = Object.keys(selectedPlan?.session?.[0] || {}).map(key => ({
        name: key,
        value: selectedPlan.session[0][key],
      }));

      setCurr(selectedPlan.currency);
      setSessions(checkArr);
    }
  }, []);

  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let dataLength = 0;
  data.forEach((item: any) => {
    if (item?.start_time) {
      dataLength += item.start_time.length;
    }
  });

  let remainingSession = count - dataLength;

  const createArrayOfObjects = (count: any) => {
    const objects = [];

    for (let i = 1; i <= count; i++) {
      objects.push(remainigData);
    }
    return objects;
  };

  // Create array of objects for remaining sessions
  const arrayOfObjects = createArrayOfObjects(remainingSession);

  const convertToUTC = (localDate: string, localTime: string, timezone: string) => {
    const localDateTime = `${localDate} ${localTime}`;
    return moment.tz(localDateTime, timezone).utc();
  };

  return (
    <>
      <ToastContainer limit={1} />
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/classes" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Enroll</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${st.spaceBox}`}></div>
          <div className={`${cx.contentBody} mt-3`}>
            <ul className={`${cx.rescheduleContent}`}>
              <li>
                <h6>{location?.state?.teacher_name || location?.state?.item?.teacher_name}</h6>
                <p className={`${cx.classPrice}`}>Price - {selectedOption ? sessions.find(session => session.name === selectedOption)?.value : ""} {curr}/Session</p>
              </li>
              <li>
                <p>{location?.state?.subject_name || location?.state?.item?.subject_name ? "Subject" : "Course"} - {location?.state?.subject_name || location?.state?.item?.subject_name || location?.state?.item?.course_name || location?.state?.item?.course_name}</p>
                <p>{location?.state?.class_name || location?.state?.item?.class_name ? "Class" : "Category"} - {location?.state?.class_name || location?.state?.item?.class_name || location?.state?.item?.category_name || location?.state?.item?.category_name}</p>
              </li>
              <li>
                <Col className="col-12">
                  <div className={`${cx.profileEdit}`}>
                    <label htmlFor="">Select Session:</label>
                    {/* <Form.Select aria-label="Default select example"
                      onChange={(e: any) => {
                        const selected = sessions.find(session => session.name === e.target.value);
                        // setSelectedSession(selected);
                        let fee = JSON.parse(e.target.value)
                        setSession(fee.name);
                        setValue(fee.value);
                        // setCount(+fee.name?.split(" ")[0]);
                      }}>
                      <option>Select Session</option>
                      {sessions?.map((e: any) => {
                        return (
                          <option value={JSON.stringify(e)}>{e?.name}</option>
                        )
                      })}
                    </Form.Select> */}

                    <li className={`${cx.dropNew}`}>
                      {/* <select value={selectedOption} onChange={handleSelectChange} style={{ width: '100%' }} >
                        {sessions.map((option, index: number) => {
                          return (
                            <option key={index} value={option.name}>{option.name}</option>
                          )
                        })}
                      </select> */}

                      <Dropdown onSelect={handleSelectChange}>
                        <Dropdown.Toggle id="dropdown-basic" style={{ width: '100%' }}>
                          {selectedOption || 'Select'} {/* Show the selected option or fallback to 'Select' */}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {sessions.map((option, index) => (
                            <Dropdown.Item key={index} eventKey={option.name}>
                              {option.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  </div>
                </Col>
              </li>
              <li>
                <p>No. of Classes -</p>
                <p>
                  <div className={`${cx.qtyBox}`}>
                    <button
                      onClick={decrementCount}>-</button>
                    <input type="text" 
                      // minLength={+session.split(" ")[0]}
                      value={count} disabled
                    />
                    <button
                      onClick={incrementCount}>+</button>
                  </div>
                </p>
              </li>
              <li>
                <p>Selected Time -</p>
                <p>
                  <div className={`${cx.qtyBox}`}>
                    <input type="text" value={selectedDayCount?.length} disabled />
                  </div>
                </p>
              </li>
            </ul>

            <CustomDatePicker getSelectedDay={selectedDay} labelFormat={"MMMM"} color={"#374e8c"} />

            <div className={`${cx.timeSlot}`}>
              <br />
              <h6 className={`${cx.timeSlot}`}>Select Time:</h6>
              <ul className={`${cx.timeSlot}`}>
                {allData?.length > 0 ?
                  get_TeacherAvailabiltyOfSlots?.map((item: any, index: any) => {
                    return (
                      <>
                        <li>
                          <label className={`${cx.labelCheckbox}`} htmlFor={`id${index}`}>
                            <input type="checkbox" id={`id${index}`}
                              checked={selectedTime?.length > 0 ?
                                selectedTime?.flat()?.some((it: any) => it?.start_time === item?.utcStart && it?.date == item?.select_date) : false}
                              value={item?.local_select_time}
                              onChange={(e: any) => {
                                var dt = moment(e.target.value, ["hh:mm:ss A", "hh:mm A", "hh:mm:ss", "hh:mm"]).format("HH:mm");
                                const datechangebasedontime = mtimezone.tz(`${date} ${dt}`, localData?.timezone).utc().format();
                                let finalData: any = datechangebasedontime.split("T");
                                let found = false;
                                for (let i = 0; i < data.length; i++) {
                                  if (data[i].date === date) {
                                    const startIndex = data[i].start_time.indexOf(item?.utcStart);
                                    if (startIndex !== -1) {
                                      // If the start time exists in this date, remove it along with the corresponding end time.
                                      data[i].start_time.splice(startIndex, 1);
                                      data[i].end_time.splice(startIndex, 1);

                                      if (data[i].start_time.length === 0) {
                                        // If no start times left, clear the whole object
                                        data[i].date = "";
                                        data[i].select_date = "";
                                        data[i].start_time = [];
                                        data[i].end_time = [];
                                        data[i].teacher_availabilty_of_slots_details_id = null;
                                      }
                                      setActive(active + 1);
                                      found = true;
                                      break;
                                    }
                                  }
                                }

                                if (!found) {
                                  // If the date doesn't exist, add a new entry
                                  const newEntry = {
                                    select_date: finalData[0],
                                    date,
                                    type: "1",
                                    start_time: [item?.utcStart],
                                    end_time: [item?.utcEnd],
                                    teacher_availabilty_of_slots_details_id: location?.state?.item?.teacher_availabilty_of_slots_details_id,
                                    teacher_availabilty_of_slots_id: location?.state?.item?.plans[0]?.teacher_availabilty_of_slots_id,
                                  };
                                  setData((prev: any) => [...prev, newEntry]);
                                  setActive(active + 1);
                                }
                                // Check if any entries need splitting
                                setData((prevData: any) => {
                                  const newData: any[] = [];
                                  prevData.forEach((entry: any) => {
                                    if (entry.date) {
                                      // If an entry has multiple times for the same date, split them into separate objects
                                      entry.start_time.forEach((start: any, index: any) => {
                                        newData.push({
                                          ...entry,
                                          start_time: [start],
                                          end_time: [entry.end_time[index]],
                                        });
                                      });
                                    }
                                  });

                                  return newData;
                                });
                                setActive(active + 1)
                              }} />
                            {item?.start_time ? (
                              <span
                                className={`${cx.checkmark} ${selectedTime?.some((it: any) => {
                                  const itemUTCStart = convertToUTC(item?.select_date, item?.utc_select_time, localData?.timezone);
                                  const selectedUTCStart = convertToUTC(it?.date, it?.start_time, localData?.timezone);
                                  return itemUTCStart.isSame(selectedUTCStart); // Consistent UTC-based comparison
                                })
                                  ? 'active' // Apply active class if time matches
                                  : ''
                                  }`}
                              >
                                {item?.start_time}
                              </span>
                            ) : (
                              <></>
                            )}
                          </label>
                        </li>
                      </>
                    )
                  }) : loaderStatus}
              </ul>
            </div>

            <div className={`${cx.timeSlot}`}>
              <Col className="col-12">
                <Button className={`btn ${cx.continue}`}
                  onClick={() => {
                    let totalTime: any = []
                    data?.map((item: any) => {
                      if (item.select_date != "")
                        totalTime.push(item?.start_time)
                    })
                    if (selectedOption == "") {
                      notify("Please select session first");
                    }
                    else if (totalTime?.flat(Infinity)?.length > count) {
                      notify("Selected slots are higher than define no. of classes, please remove some slots");
                    }
                    else (
                      navigate.push("/student/purchase-class", {
                        state: {
                          details: location?.state, count: count,
                          price: sessions.find(session => session.name === selectedOption)?.value
                        },
                        dataArr: data, date,
                        remainigData: arrayOfObjects?.flat()
                      })
                    )
                  }}>
                  Next
                </Button>
              </Col>
            </div>
            <p style={{ padding: "10px" }}><b>Note:</b> You can also skip selecting slots and purchase classes in one go and schedule them later as per your convenience</p>
          </div>
          <div className={`pt-3`}></div>
        </div>
      </section >
      <Footer />
    </>
  );
}
// export enum Roles {
//   ADMIN = "admin",
//   SUBADMIN = "Subadmin",
//   PARTICIPANTS = "student",
//   COACH = "coach",
// }

export enum StudentRoutes {
  STUDENT_LOGIN = "/student/login",
  ERROR_404 = "/student/error404",
  STUDENT_SIGNUP = "/student/signup",
  STUDENT_SIGNUPID = "/student/signup/:id",
  STUDENT_FORGOT_PASSWORD = "/student/forgot-password",
  STUDENT_CHANGE_PASSWORD = "/student/change-password",
  STUDENT_CHANGE_PASSWORD_HOME = "/student/change-your-password",
  STUDENT_PASSWORD_FORGOT = "/student/password-forgot",
  STUDENT_COMPLETE_PROFILE = "/student/complete-profile",
  STUDENT_OTP_VERIFICATION = "/student/otp-verification",
  STUDENT_HOME = "/student/home",
  STUDENT_CLASS_DETAILS = "/student/class-details",
  STUDENT_APPLIED = "/student/applied",
  STUDENT_COURSE = "/student/course",
  STUDENT_CLASSES = "/student/classes",
  STUDENT_CALENDAR = "/student/calendar",
  STUDENT_PROFILE = "/student/profile",
  STUDENT_EDIT_PROFILE = "/student/edit-profile",
  STUDENT_CATEGORY_DETAILS = "/student/categorydetails",
  STUDENT_REFERRAL_REWARDS = "/student/referral-rewards",
  STUDENT_RESCHEDULE = "/student/reschedule",
  STUDENT_ENROLL = "/student/enroll",
  STUDENT_WALLET = "/student/wallet",
  STUDENT_PURCHASE_CLASS = "/student/purchase-class",
  STUDENT_PRIVACY_POLICY = "/student/privacy-policy",
  STUDENT_TERMS_AND_CONDITION = "/student/Terms-and-conditions",
  STUDENT_HELP_AND_SUPPORT = "/student/help-and-support",
  STUDENT_NOTIFICATION = "/student/notification",
  STUDENT_PAYMENT_HISTORY = "/student/payment-history",
  STUDENT_ABOUT_US = "/student/about-us",
  STUDENT_PLAN_SELECT = "/student/plan-select",
}

export enum TeacherRoutes {
  TEACHER_LOGIN = "/teacher/login",
  TEACHER_SIGNUP = "/teacher/signup",
  TEACHER_FORGOT_PASSWORD = "/teacher/forgot-password",
  TEACHER_OTP_VERIFICATION = "/teacher/otp-verification",
  TEACHER_CHANGE_PASSWORD = "/teacher/change-password",
  TEACHER_CHANGE_PASSWORD_HOME = "/teacher/change-your-password",
  TEACHER_COMPLETE_PROFILE = "/teacher/complete-profile",
  TEACHER_AVAILABILITY = "/teacher/availability",
  TEACHER_RESCHEDULE = "/teacher/reschedule",
  TEACHER_PROPTECTED_AVAILABILITY = "/teacher/pavailability",
  TEACHER_HOME = "/teacher/home",
  TEACHER_CLASSES = "/teacher/classes",
  TEACHER_VIEW_MORE = "/teacher/view-more",
  TEACHER_CALENDAR = "/teacher/calendar",
  TEACHER_PROFILE = "/teacher/profile",
  TEACHER_EDIT_PROFILE = "/teacher/edit-profile",
  TEACHER_NOTIFICATION = "/teacher/notification",
  TEACHER_ABOUT_US = "/teacher/about-us",
  TEACHER_PRIVACY_POLICY = "/teacher/privacy-policy",
  TEACHER_TERMS_AND_CONDITIONS = "/teacher/terms-and-conditions",
  TEACHER_MYEARNING = "/teacher/my-earning",
  TEACHER_TEACHING_DETAILS = "/teacher/teaching-details",
  TEACHER_TEACHING_VIEW = "/teacher/teaching-view",
  TEACHER_PASSWORD_FORGOT = "/teacher/password-forgot",
  TEACHER_APPLY = "/teacher/apply",
  TEACHER_INFO = "/teacher/info",
  TEACHER_PAYOUT = "/teacher/payout",
  TEACHER_ADD = "/teacher/add",
  TEACHER_BASIC_DETAILS = "/teacher/basic-detail",
  TEACHER_BASIC_DETAILS_LIST = "/teacher/basic-detail-list/:id", 
}

export enum CommonRoutes {
  STUDENT_CHATS = "/student/chats",
  TEACHER_CHATS = "/teacher/chats",
}

export enum PublicRoutes {
  BASE_LOGIN_ROUTE = "/",
  PRIVACY_POLICY = "/privacy-policy",
  BASE_ERROR_ROUTE = "/error",
}
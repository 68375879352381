import React, { useEffect, useState } from "react";
import cx from "./Header.module.scss";
import logo from "../../images/logo.png";
import classes from "../../images/classes.svg";
import profile from "../../images/profile.svg";
import calendar from "../../images/calendar.svg";
import gift from "../../images/gift.svg";
import axios from "axios";
import { FaClock } from "react-icons/fa";
import wallet from "../../images/wallet.svg";
import home from "../../images/home.svg";
import { BiSearch } from "react-icons/bi";
import { MdNotifications } from "react-icons/md";
import { Col, Row, Dropdown } from "react-bootstrap"
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { AiFillGift, AiFillMessage } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeNotifyCount } from "../../redux-toolkit/reducer/notifyCount";
import { Unsubscribe, collection } from 'firebase/firestore';
import {
    query,
    orderBy,
    onSnapshot,
    limit,
} from "firebase/firestore";
import { db } from "../../firebase";
import ProfileAvatar from "../../Custom_Components/ProfileAvatar/ProfileAvatar";
import { changeClassCount } from "../../redux-toolkit/reducer/classCount";

const Header = (props: any) => {
    let localData = JSON.parse(localStorage.getItem("userData")!);
    const dispatch = useDispatch();
    const notify = useSelector((state: any) => state?.notifyCount);
    const classCount = useSelector((state: any) => state?.classCount);
    const [search, setSearch] = useState("");
    const [reciverId, setReciverId] = useState<any>(null);
    const [recivedMsgs, setRecivedMsgs] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const navigate = useHistory();
    let location: any = useLocation();

    useEffect(() => {
        if (localData.api_token) {
            if (location?.pathname == "/student/chats" || location?.pathname == "/teacher/chats") {
                notification();
            } else {
                notification();
                setInterval(() => {
                    notification();
                }, 8000);
            }
        }
    }, [])

    useEffect(() => {
        if (search != "") {
            let arr: any[] = [{
                classes: [],
                category: [],
                subject: [],
                demoRequest: []
            }]
            props?.forSearchData?.map((item: any) => {
                if (item?.data?.class_name?.toLowerCase()?.includes(search?.toLowerCase())) {
                    if (item?.type == "class")
                        arr[0].classes.push(item)
                }
                if (item?.data?.name?.toLowerCase()?.includes(search?.toLowerCase())) {
                    if (item?.type == "category") { arr[0].category.push(item) }
                    if (item?.type == "category_subject") { arr[0].subject.push(item) }
                }
                if (item?.data?.title?.toLowerCase()?.includes(search?.toLowerCase()))
                    arr[0].subject.push(item)
                if (item?.data?.subject_name?.toLowerCase()?.includes(search?.toLowerCase())) {
                    if (item?.type == "demo_request")
                        arr[0].demoRequest.push(item)
                }
            })
            if (arr.length > 0) {
                setData(arr)
            }
        } else {
            setData([])
        }
    }, [search])

    // ----------------------- NOTIFCATION API ----------------------- //
    function notification() {
        let formdata = new FormData();
        var config = {
            method: "get",
            url: `${process.env.REACT_APP_BASEURL}/${localData?.role_id == 6 ? 'student_notification_count' : 'teacher_notification_count'}`,
            headers: {
                Authorization: "Bearer" + localStorage.getItem("api_token"),
            },
            data: formdata,
        };
        axios(config)
            .then(function (response) {
                setData(response?.data?.data);
                if (response?.data?.statusCode == 400) {
                    localStorage.removeItem("api_token");
                    localStorage.removeItem("userData");
                    localStorage.removeItem("teacher_rating");
                    alert(response?.data?.message);
                    navigate?.push('/');
                }
                localStorage.setItem("teacher_rating", JSON.stringify(response.data.rating ? response.data.rating : 0));
                dispatch(changeNotifyCount(response?.data?.count))
                dispatch(changeClassCount(response?.data?.class_count + response?.data?.demo_count))
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    // -------------------------------------------- UPCOMING CLASS API --------------------------------------------//
    function upcomingClasses() {
        let formdata = new FormData();
        var config = {
            method: "get",
            url: `${process.env.REACT_APP_BASEURL}/${localData?.role_id == 6 ? 'upcomming_past_data' : 'teacher_upcomming_past_data'}`,
            headers: {
                Authorization: "Bearer" + localStorage.getItem("api_token"),
            },
            data: formdata,
        };
        axios(config)
            .then(function (response) {
                const chatNames = response?.data?.data?.filter((obj: any, index: any) =>
                    response.data.data.findIndex((event: any) => event.teacher_id == obj.teacher_id) == index
                );
                const filterItemArr: any = [];
                let allChatID: any = []
                chatNames.map((filterItem: any) => {
                    allChatID?.push(localData?.role_id == 6 ? `${filterItem?.teacher_id}_${localData?.id}` : `${filterItem?.student_id}_${localData?.id}`)
                    setReciverId(localData?.role_id == 6 ? filterItem?.teacher_id : filterItem?.student_id)
                    filterItemArr?.push(localData?.role_id == 6 ? filterItem?.teacher_id : filterItem?.student_id);
                })
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    }

    useEffect(() => {
        if (location?.pathname != "/student/chats" || location?.pathname != "/teacher/chats") {
            upcomingClasses();
        }
    }, [])

    // {UNREAD MESSAGE FUNCTIONALITY}
    let trueValues: any[] = [];
    if (trueValues?.length === 0) {
        recivedMsgs?.forEach((item: any) => {
            if (item?.seen == false) {
                trueValues?.push(item?.seen);
            }
        });
    }

    const reciversChatId = `${reciverId}_${localData?.id}`

    // -------------------------------------- FOR RECIVER CHAT COUNT -------------------------------------
    useEffect(() => {
        const q = query(
            collection(db, 'messages', 'solo-message', reciversChatId),
            orderBy('createdAt', 'desc'),
            limit(50)
        );
        const unsubscribe: Unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            const fetchedMessages: any = [];
            QuerySnapshot.forEach((doc) => {
                fetchedMessages.push({ ...doc.data(), id: doc?.id });
            });
            const sortedMessages = fetchedMessages.sort(
                (a: any, b: any) => a.createdAt - b.createdAt
            );
            setRecivedMsgs(sortedMessages);
        });
        return unsubscribe;

    }, [reciversChatId]);

    return (
        <>
            <div className={`${cx.headerStart}`}>
                <div className={`${cx.headerTop}`}>
                    <div className={'container'}>
                        <Row className={` align-items-center ${cx.titleBar}`}>
                            <Col className={`col-3`}>
                                <NavLink to={localData?.role_id == 6 ? "/student/home" : "/teacher/home"} className={`${cx.logo}`}>
                                    <img alt="logo" src={logo} />
                                </NavLink>
                            </Col>
                            <Col className={`col-8`}>
                                <div className={`${cx.titleBar} ${cx.fixHeader}`}>
                                    {location?.pathname === "/student/home" && localData?.role_id == 6 ? <div className={`${cx.searchBox}`}>
                                        <BiSearch className={`${cx.icon}`} />
                                        <input type="text" className="form-control" placeholder="Search" value={search} onChange={(e: any) => {
                                            setSearch(e?.target?.value);
                                        }} />
                                        {
                                            data?.[0]?.classes?.length > 0 || data?.[0]?.category?.length > 0 || data?.[0]?.subject?.length > 0 || 0 ?
                                                <div className={`${cx.searchListView}`}>
                                                    <ul>
                                                        {
                                                            data[0]?.classes?.map((item: any, index: number) => {
                                                                return (
                                                                    <li onClick={() => {
                                                                        props?.searchFilter(item?.data, "classes")
                                                                        setData([])
                                                                        // setClassOrCategory("classes");
                                                                        props?.classcategorysearch("classes")
                                                                    }}>
                                                                        {item?.data?.class_name}
                                                                    </li>
                                                                )
                                                            })
                                                        }{
                                                            data[0]?.category?.map((item: any, index: number) => {
                                                                return (
                                                                    <li onClick={() => {
                                                                        props?.searchFilter(item?.data, "category")
                                                                        setData([])
                                                                        // setClassOrCategory("category");
                                                                        props?.classcategorysearch("category")
                                                                    }}>
                                                                        {item?.data?.name}
                                                                    </li>
                                                                )
                                                            })
                                                        }{
                                                            data[0]?.subject?.map((item: any, index: number) => {
                                                                return (
                                                                    <li onClick={() => {
                                                                        if (item?.type == "class_subject") {
                                                                            navigate.push("/student/class-details", {
                                                                                ...item?.data,
                                                                                imageUrl: "https://admin.guruathome.com/public"
                                                                            })
                                                                        }
                                                                        if (item?.type == "category_subject") {
                                                                            navigate.push("/student/categorydetails", {
                                                                                ...item?.data,
                                                                                imageUrl: "https://admin.guruathome.com/public/course/",
                                                                            })
                                                                        }
                                                                        setData([])
                                                                    }}>
                                                                        {item?.data?.name ? item?.data?.name : item?.data?.title} - {item?.name}
                                                                    </li>
                                                                )
                                                            })
                                                        }{
                                                            data[0]?.demoRequest?.map((item: any, index: number) => {
                                                                return (
                                                                    <li key={index} onClick={() => {
                                                                        navigate.push("/student/applied", {
                                                                            state: item?.data,
                                                                            imageUrl: "https://admin.guruathome.com/public",
                                                                            courseUrl: "https://admin.guruathome.com/public/course/"
                                                                        },)
                                                                        setData([])
                                                                    }}>
                                                                        {item?.data?.subject_name} - {item?.data?.class_name} - (Demo)
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div> : ""}
                                    </div> : ""}
                                    {localData?.role_id == 2 &&
                                        <NavLink to={"/teacher/basic-detail"} className={`${cx.notification}`}>
                                            <FaClock />
                                            {classCount?.classCount > 0 ? <span>{classCount?.classCount}</span> : ''}
                                        </NavLink>}
                                    <NavLink to={localData?.role_id == 6 ? "/student/notification" : "/teacher/notification"} className={`${cx.notification}`}>
                                        <MdNotifications />
                                        {notify?.notifyState > 0 ? <span>{notify?.notifyState}</span> : ''}
                                    </NavLink>
                                    {localData?.role_id == 6 ? <NavLink to="/student/referral-rewards" className={`${cx.notification}`}>
                                        <AiFillGift />
                                    </NavLink> : ''}
                                    <NavLink to={localData?.role_id == 6 ? "/student/chats" : "/teacher/chats"} className={`${cx.notification}`}>
                                        <AiFillMessage />
                                        {trueValues?.length > 0 ? <span>{trueValues?.length}</span> : ''}
                                    </NavLink>
                                </div>
                            </Col>
                            <Col className={`col-1`}>
                                <div className={`${cx.headerUser}`}>
                                    <Dropdown>
                                        <Dropdown.Toggle className={`${cx.headerDrop}`}>
                                            {localData?.image == "" || localData?.image == null ?
                                                <ProfileAvatar name={localData?.name} />
                                                : <img src={localData?.image} alt='userimg' />}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className={`${cx.DropdownSelects}`}>
                                            <NavLink to={localData?.role_id == 6 ? "/student/home" : "/teacher/home"}>
                                                <img src={home} className={`${cx.icon}`} alt='home' />
                                                Home
                                            </NavLink>

                                            <NavLink to={localData?.role_id == 6 ? "/student/classes" : "/teacher/classes"}>
                                                <img src={classes} className={`${cx.icon}`} alt='classes' />
                                                Classes
                                            </NavLink>

                                            <NavLink to={localData?.role_id == 6 ? "/student/calendar" : "/teacher/calendar"}>
                                                <img src={calendar} className={`${cx.icon}`} alt='calendar' />
                                                Calendar
                                            </NavLink>

                                            {localData?.role_id == 6 ? <NavLink to="/student/referral-rewards">
                                                <img src={gift} className={`${cx.icon}`} alt='gift' />
                                                Gift
                                            </NavLink> : ''}

                                            <NavLink to={localData?.role_id == 6 ? "/student/wallet" : "/teacher/my-earning"}>
                                                <img src={wallet} className={`${cx.icon}`} alt='wallet' />
                                                Wallet
                                            </NavLink>
                                            <NavLink to={localData?.role_id == 6 ? "/student/profile" : "/teacher/profile"} >
                                                <img src={profile} className={`${cx.icon}`} alt='profile' />
                                                Profile
                                            </NavLink>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div >
        </>)
}

export default Header;